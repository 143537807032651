.sidebar {
  width: 250px;
  background-color: #f0f0f0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100vh;
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
}

.sidebar nav {
  flex: 1; /* Take up remaining space, pushing logout to the bottom */
}

.header-image {
  padding-bottom: 20px;

  .menuCloseHeader {
    display: none;

    .menuCloseButton {
      display: none;
    }
  }
}

.sidebar nav ul {
  padding-top: 10px;
  padding-left: 0px;
  margin: 0;
  list-style: none;
}

.sidebar nav ul li {
  margin: 5px 0;
  font-family: "rocnationFontBold";
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center; /* Align icon and text vertically */
  &.active {
    background-color: #000;
    border-radius: 3px;
    a {
      color: #f0f0f0;
    }
  }
  &:hover {
    a {
      color: #f0f0f0;
    }
    border-radius: 3px;
    background-color: #777777;

    
  }
  .icon {
    padding-right: 10px;
    height: 22px !important;
  }
}

.sidebar nav ul li a {
  text-decoration: none;
  color: #a1a4a6;
  font-weight: bold;
  padding: 10px;
  display: flex;
  align-items: center;
}

.logout-section {
  padding: 10px;
  font-family: "rocnationFontBold";
  display: flex;
  align-items: center; /* Align icon and text vertically */
  a {
    text-decoration: none;
    color: #a1a4a6;
    font-weight: bold;
  }
  .logout-icon {
    padding-right: 10px;
    height: 22px !important;
  }
}

@media (max-width: 768px) {
  .header-image {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .menuCloseHeader {
      padding: 20px 0 40px;
      display: block;

      .menuCloseButton {
        padding: 0;
        min-width: auto;
        display: block;
      }
    }
  }
}
