.calculate-certification-button {
  margin-top: 1rem !important;
  color: black !important;
  font-size: 14px;
  cursor: pointer !important;
  background-color: #ccc6be !important;
  font-family: "rocnationFontSuisse", sans-serif;
  border: none;
  width: 133px;
  height: 40px;
  transition: background-color 0.3s ease;
  align-items: center; /* Align items vertically center */
  justify-content: center; /* Center items horizontally */

  .MuiButton-startIcon {
    margin-right: 5px; /* Space between icon and text */
  }

  &:hover {
    background-color: darken(#aaa, 10%) !important;
  }
}

.customPaper {
  width: 675px;
  height: 200px;
}

@media (max-width: 1000px) {
  .customPaper {
    width: 450px;
  }
}

@media (max-width: 768px) {
  .customPaper {
    width: calc(100% - 40px);
  }
}

@media (max-width: 500px) {
  .typography-header{
    font-size: 11px !important;
  }

  .typography-content{
    font-size: 9px !important;
  }

  .typography-footer{
    font-size: 11px !important;
  }
}
