.login-component {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f6f6f6;
}

.login-container {
  width: 60%;
  height: 80%;
  display: flex;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .form-container {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .login-title {
      margin-bottom: 1em;
      text-align: center;
    }

    .login-form {
      padding: 2em;

      .input-field {
        margin-bottom: 1em;
        padding-right: 5px;
        input {
          width: 100%;
          padding-top: 10px;
          padding-bottom: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
          padding-left: 5px;

          &:focus {
            outline: none;
            border-color: #007bff;
          }
        }
      }

      .password-reset {
        text-align: center;
        margin-bottom: 1em;
      }

      .login-button {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 4px;
        background-color: #ccc6be;
        color: black;
        text-transform: none;
        font-weight: bold;
        cursor: pointer;
        font-family: "rocnationFontSuisse", sans-serif;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: darken(#aaa, 10%);
        }
      }
    }
  }
  .form-image {
    width: 45%;
    height: 100%;
    background: url("../../assets/7d823f1da23f820d7da80d40d91966ec.jpg")
      no-repeat center center;
    background-size: cover;
    position: relative; // For the overlay positioning

    .text-overlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); // Centers the element
      color: white;
      padding: 0.5em 1em;
    }
  }
}

@media (max-width: 1000px) {
  .login-component {
    padding: 0 15px;
  }
  .login-container {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .login-container {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .login-container {
    flex-direction: column-reverse;
    padding: 2rem;

    .form-container {
      width: 100%;
    }
    .form-image {
      width: 100%;
      background: url("../../assets/7d823f1da23f820d7da80d40d91966ec.jpg")
        no-repeat center center;
      background-size: contain;
    }
  }
  
}
