@font-face {
  font-family: "rocnationFontBold"; // Give your font a name to be used within your CSS
  src: url("../assets/fonts/ROCNATIONBold-Regular.otf") format("opentype"); // Adjust the path to where you've stored your font file
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "rocnationFontSuisse"; // Give your font a name to be used within your CSS
  src: url("../assets/fonts/SuisseNeue-Regular.otf") format("opentype"); // Adjust the path to where you've stored your font file
  font-weight: normal; // Define the weight of the font
  font-style: normal; // Define the style of the font
}

body {
  margin: 0;
  padding: 0;
  font-family: "rocnationFontSuisse", sans-serif;
  owerflow-x: hidden;

  button {
    background-color: #ccc6be;
    font-family: "rocnationFontSuisse", sans-serif;
  }

  .active {
    background-color: #000000;
    a {
      color: white;
    }
  }

  .header-image {
    display: flex;

    .logo {
      height: 48px; // Adjust based on your logo's size
      //margin-right: 20px;

      img {
        height: 100%;
        width: auto;
      }
    }
  }

  .dashboard table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px; /* Adjust as needed */
  }

  .dashboard th,
  .dashboard td {
    text-align: left;
    padding: 8px; /* Adjust as needed */
    border-bottom: 1px solid #e0e0e0; /* Light grey line */
  }

  .dashboard th {
    background-color: #f0f0f0; /* Light grey background */
    color: #333; /* Dark text color */
    font-weight: normal;
  }

  .dashboard td {
    background-color: #fff; /* White background for cells */
    color: #666; /* Lighter text color */
  }

  .selectors {
    display: flex;
    align-items: center;
  }

  .selectors-container {
    display: flex;
  }

  .client-selector {
    margin: 15px 50px 15px 0;
    align-items: center;
    width: 300px;
    height: 50px;

    label {
      margin-right: 10px;
      //font-weight: bold;
    }

    select {
      padding: 5px 10px;
      font-size: 16px;
    }
  }

  .form-label-custom {
    font-family: "rocnationFontSuisse", sans-serif;
    font-size: 10px;
    color: black;
  }

  .checkbox-option {
    display: flex;
    align-items: center;
    background-color: transparent !important; /* Ensure background color doesn't change when selected */
    color: black;
  }

  .custom-checkbox {
    margin-right: 8px;
    appearance: none; /* Remove default checkbox styling */
    width: 16px;
    height: 16px;
    border: 1px solid gray;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
  }

  .custom-checkbox:checked {
    background-color: gray; /* Set checkbox color to gray when checked */
    border-color: gray; /* Ensure the border is also gray when checked */
  }

  .custom-checkbox:checked::after {
    content: "✔"; /* Use a checkmark symbol */
    display: block;
    color: white;
    font-size: 12px;
    text-align: center;
    line-height: 16px;
  }

  .form-control-custom {
    width: 332px;
  }

  input::placeholder,
  textarea::placeholder,
  .basic-multi-select::placeholder {
    color: #c0c0c0;
    font-family: "rocnationFontSuisse", sans-serif;
    /* Add other styles as needed */
  }
}

@media (max-width: 1000px) {
  .selectors {
    flex-direction: column;
    align-items: flex-start !important;

    .selectors-container {
      flex-direction: column;
    }
  }
}

@media (max-width: 768px) {
  .selectors {
    flex-direction: row;

    .selectors-container {
      flex-direction: column;
    }
  }
}

@media (max-width: 500px) {
  .selectors {
    flex-direction: column;
  }
}
