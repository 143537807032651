Table {
    font-family: 'rocnationFontBold';
   }

/* Custom scrollbar for Chrome, Safari and Opera */
.table-content::-webkit-scrollbar {
    width: 10px;
  }
  
.table-content::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
  }
  
  .table-content::-webkit-scrollbar-track {
    background: transparent;
  }
  
  /* Hide scrollbar for Chrome, Safari and Opera by default */
  .table-content {
    margin-top: 10px;
    overflow-y: hidden;
    max-height: 400px; /* Adjust based on your needs */
  }
  
  /* Show scrollbar on hover */
  .table-content:hover {
    overflow-y: auto;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .top-songs {
    margin-top: 18px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  
  /* Show scrollbar for Firefox on hover */
  .table-content:hover {
    scrollbar-width: auto;  /* Firefox */
    overflow-y: auto;
  }
  
  .top-songs-title{
    font-family: 'rocnationFontBold';
  }
  