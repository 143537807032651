.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .selectors {
    display: flex;
  }
  .calculate-button {
    margin-top: 2rem !important;
    color: black !important;
    font-size: 14px;
    cursor: pointer !important;
    background-color: #ccc6be !important;
    font-family: "rocnationFontSuisse", sans-serif;
    border: none;
    width: 133px;
    height: 40px;
    transition: background-color 0.3s ease;
    align-items: center; /* Align items vertically center */
    justify-content: center; /* Center items horizontally */

    .MuiButton-startIcon {
      margin-right: 5px; /* Space between icon and text */
    }

    &:hover {
      background-color: darken(#aaa, 10%) !important;
    }
  }
  /* RevenueCard.css */
  .revenue-card {
    border: 1px solid #ccc6be;
    padding: 20px;
    border-radius: 5px;
    background-color: #f6f6f6;
    display: inline-block;
    margin-right: 2rem;
    margin-top: 3rem;
    width: 200px;
    text-align: left;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .label {
    font-size: 14px;
    color: #888;
    margin-bottom: 10px;
  }

  .value {
    font-size: 30px;
    font-family: "rocnationFontBold", sans-serif;
    color: #000;
  }
}

