.mainLayout {
  display: flex;

  .sidebarDrawer {
    display: none;
  }

  .contentWrapper {
    display: flex;
    width: 100%;

    .sidebarMenu {
      display: block;
      height: 100vh;
    }

    .contentArea {
      flex-grow: 1;
      overflow: auto;
      padding: 20px;
      height: calc(100vh - 40px);
    }
  }
}

@media (max-width: 768px) {
  .mainLayout {
    display: flex;
    flex-direction: column;

    .sidebarDrawer {
      display: block;
      .menuHeader {
      
        padding: 40px 20px 20px;
        .menuButton {
          min-width: auto;
          padding: 0;
        }
      }

      .MuiDrawer-root {
        padding: 15px;
        .menuCloseHeader {
        
          padding: 40px 20px 20px;
          .menuButton {
            min-width: auto;
            padding: 0;
          }
        }
      }

    }

    .contentWrapper {
      .sidebarMenu {
        display: none;
      }
    }
  }
}
