// In src/components/Header/Header.scss

.header {
  //display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  background-color: #fff;

  .padding-bottom {
    padding-bottom: 20px;
  }

  .title-section {
    display: flex;
    align-items: center;
    padding-top: 10px;
  }

  .title {
    font-family: "rocnationFontBold";
    font-weight: 400;
    font-size: 20px;
  }

  .clear-button {
    margin-left: auto;
    padding: 5px 15px;
    color: black;
    width: 133px;
    height: 40px;
    font-size: 14px;
    cursor: pointer;
    background-color: white;

    border: 1px solid #ccc6be;
    height: 40px;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: darken(#ccc6be, 10%);
    }
  }

  .refresh-button {
    margin-left: auto;
    margin-top: 30px;
    padding: 5px 15px;
    color: black;
    //font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    background-color: #ccc6be;
    font-family: "rocnationFontSuisse", sans-serif;
    border: none;
    height: 40px;
    transition: background-color 0.3s ease;
    display: flex; /* Ensure flexbox is used for alignment */
    align-items: center; /* Align items vertically center */
    justify-content: center; /* Center items horizontally */

    .MuiButton-startIcon {
      margin-right: 5px; /* Space between icon and text */
    }

    &:hover {
      background-color: darken(#aaa, 10%);
    }
  }
}

@media (max-width: 1000px) {
  .refresh-button {
    margin-left: 0 !important;
  }
}

@media (max-width: 768px) {
  .refresh-button {
    margin-left: auto !important;
    margin-top: 30px;
  }
}

@media (max-width: 500px) {
  .title-section {
    flex-direction: row;

    .title {
      word-wrap: normal;
      width: 180px;
    }
  }
  .refresh-button {
    margin-left: 0 !important;
  }
}
